[data-v-6a0953b8] .el-form-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-6a0953b8] .topForm .el-select {
  width: 210px !important;
}
[data-v-6a0953b8] .tag-list .el-select {
  width: 60px !important;
}
[data-v-6a0953b8] .flexSelect .el-select {
  width: 70px !important;
}
[data-v-6a0953b8] .avue-form__item-- {
  margin-bottom: 0 !important;
}
[data-v-6a0953b8] .treeForm .el-form-item__content {
  height: 30px !important;
}
[data-v-6a0953b8] .el-dialog__body {
  padding: 0 20px;
}
.el-cascader-panel[data-v-6a0953b8] {
  height: 300px;
}
[data-v-6a0953b8] .el-table .el-table__cell {
  padding: 10px 0;
}
.main[data-v-6a0953b8] {
  background: #fff;
  min-height: calc(100vh - 122px);
  padding: 0 10px;
  padding-bottom: 10px;
}
.main .add-btn[data-v-6a0953b8] {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0px 4px;
  /* margin-top: 10px; */
  display: inline-block;
  margin: 10px 0;
  font-size: 14px;
}
.main .tag-list[data-v-6a0953b8] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.main .save-btn[data-v-6a0953b8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.main .table-r[data-v-6a0953b8] {
  padding: 0 10px;
}
.makingClass[data-v-6a0953b8] {
  padding-bottom: 10px;
  padding-top: 10px;
}
.el-icon-delete[data-v-6a0953b8] {
  display: block;
}
#edui148[data-v-6a0953b8],
#edui153[data-v-6a0953b8],
#edui165[data-v-6a0953b8],
#edui170[data-v-6a0953b8],
#edui175[data-v-6a0953b8] {
  display: none !important;
}