.tableColumn[data-v-a44c2da8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.tableColumn .popover[data-v-a44c2da8] {
  width: 20px !important;
}